.App {
  height: 100vh;
  width: 100%;
  display: flex;

  .app-container {
    align-items: center;
    background-image: url("./img/home-bg.png");
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.75);
    display: flex;
    justify-content: center;
    width: 100%;

    &-logo {
      height: 500px;
    }
  }
}
